.notification-title {
    color: var(--bg-theme);
  }

  .modalRight {
    max-width: 500px !important;
  }
  
  .modalRight .modal-header {
    background: var(--bs-white);
    position: static !important;
    font-size: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  }
  
  .modalRight .modal-header p {
    position: absolute;
    right: 50px;
    top: 15px;
  }
  
  .modal-open .modalRight {
    left: 0 !important;
  }
 
  .modalRight {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    height: 100%;
    left: 100% !important;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out !important;
    background: var(--bg-color-cfb);
    box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 0px 24px 0px 0px;
    max-width: 500px;  
  }

  .modal-content {
      border: none;
  }