.sidebar {
    position: fixed;
    top: 20px;
    bottom: 0;
    left: 0;   
    padding: 0px; 
    background-color: var(--color-2f2); 
    width: 235px;
    z-index: 2;
}

.sidebar_item_container{
    position: absolute;
    width: 215px;
    height: 64px;
    left: 0px;  
    border-radius: 0px 24px 24px 0px; 
}

.icon{    
    margin-left: 30px;
    color: var(--color-737);
    position: absolute;   
}

.side-rect{
    width: 8px;
    height: 64px;
    left: 0px;
}

.normal_text {
    position: absolute;
    left: 84px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-737);
}

.active_text {
    position: absolute;
    left: 84px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color:var(--bg-theme);
}

.footer {   
    width: 100%;
    height: 39px;
    position: absolute;
    bottom: 10px;
    font-family: var(--font);
    text-align: center;
    font-weight: normal;
    color: var(--bg-theme);
    font-size: 14px;
    line-height: 12px;
    text-decoration: underline;
}

.footer button {
    font-size: 14px;
    font-weight: 500;
    color: var(--bg-theme);    
    text-decoration: underline;
}


@media screen and (max-width:1199px) {
    .sidebar {
        transform: translateX(-100%);
        transition: all ease .4s;
    }
    .sidebar_item_container {
        transform: translateX(-100%);
        transition: all ease .4s;
    }
    .footer {
        transform: translateX(-100%);
        transition: all ease .4s;
    }
    .sidebar.open, .sidebar.open .sidebar_item_container, .sidebar.open .footer {
        transform: none;
    }
}