.balancelabel{
    color: var(--color-080);
}

.modal-content .table th, .modal-content .table td {
    padding: 0 10px 25px 0;
}

.modal-content .efunder-btn {
    min-width: 140px;
    font-size: 15px;
    padding: 10px 15px;
}

.efunder-btn {
    background-color: var(--bg-theme);
    color: var(--bs-white);
    text-align: center;
    display: inline-block;
    padding: 13px 15px;
    border: none;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 18px;
    min-width: 180px;
    font-weight: 700;
}

.efunder-btn:hover, .efunder-btn:focus {
    background-color: var(--bg-theme);
    color: var(--bs-white);
}
  
.efunder-btn:hover {
    text-decoration: none;
}

.modal-content .form-control {
    width: 100%;
    margin-bottom: 20px;
    height: 46px;
    border-color: var(--border-4da);
    font-size: 1rem;
}

.cursor {
    cursor: pointer;
}

.text-through {
    text-decoration: line-through;
}

.paid-label {
    margin-bottom: 6px;
    min-height: 20px;
}

.accordion-title {
  padding-right: 20px;
  position: relative;
}

.accordion-title svg {
  float: right;
}