.selectBPContainer {
    margin-left: 5.5vw;
}
  
.inColumn {
    display: flex;
    flex-direction: column;
}

.inRow {
    display: flex;
    flex-direction: row;
    width: 120px;
}
  
.realButton {
    color: var(--bs-var(--bs-white));
    background-color: var(--bs-var(--bs-white));
    border-width: 1px;
    border-radius: 8px;
    border-color: var(--border-9d8);
  }

 .realButton:focus {
    outline: none;
  }
 
 .imgButton {
    width: 130px;
    height: 130px;
    border: 2px solid var(--border-eee);
    border-radius: 8px;
    background-color: var(--bs-white);
    outline: none;
    border-color: var(--border-9d8);
    text-align: center;
    padding: 0 !important;
    padding-bottom: 20px;
  }

  button.imgButton:hover {
    border: 2px solid var(--border-9d8);
  }

  .imgButtonActive {
    width: 130px;
    height: 130px;
    border-width: 3px;
    border-radius: 8px;
    background-color: var(--bs-white);
    outline: none;
    border: 2px solid var(--bg-theme);
    text-align: center;
    padding: 0 !important;
    padding-bottom: 20px;
  }
  
  .imgButtonActive:focus, .imgButton:focus {
    outline: none !important;
  }
  
  .buttonLabel {
    font-size: 20px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    color: var(--color-c6c);    
    text-align: center;
  }
  
  .buttonLabelActive {
    font-size: 20px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;    
    text-align: center;
    color: var(--bg-theme);
  }
  
  .CreateAccformSelectBP {
    margin-top: 50px;
    width: 100%;
    height: 54px;
    background: var(--bg-theme);
    border-radius: 8px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    border: none;
    align-items: center;
    text-align: center;
    color: var(--color-2f2);
  }
  
  .CreateAccformSelectBPDisabled {
    margin-top: 50px;
    width: 100%;
    height: 54px;
    background: var(--border-9d8);
    border: transparent;
    border-radius: 8px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    color: var(--color-2f2);
    outline: none;
  }
  
.CreateAccformLogin {
    margin-top: 4.5vh;
    width: 250px;
    height: 54px;
    margin-left: 6.5vw;
    background: var(--bg-theme);
    border-radius: 8px;
    border: none;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    color: var(--color-2f2);
}
  
.CreateAccformLoginDisabled {
    margin-top: 4.5vh;
    width: 250px;
    height: 54px;
    margin-left: 6.5vw;
    background: var(--border-9d8);
    border-radius: 8px;
    border: none;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    color: var(--color-2f2);
}
  
.Thescroll {
    margin: 4px, 4px;
    padding: 4px;
    background-color: var(--bg-color-cfb) !important;  
    height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    margin-right: 50px;
}
  
.platform-item > h3 {
    font-size: 22px;
}
  
.platform-popup .imgButton:focus {
    border: 2px solid var(--border-9d8);
}

.modalRight {
    max-width: 500px !important;
    background-color: var(--bg-color-cfb) !important;
}
._form .welcome {
    padding-bottom: 10px;
    font-family: var(--font);
    font-size: 36px;
    line-height: 38px;
    color: var(--bg-theme);
}

.selectBPContainer.row  {
    margin-left: 0;
}

.CreateAccformSelectBP {
    width: 100% !important;
}

.platform-popup .row.justify-content-center {
    justify-content: flex-start !important;
}

.linked-wrap {
    text-align: center;
}