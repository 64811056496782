#container{    
    border-radius: 40px 40px 0px 0px;
    margin-left: 235px;
    margin-top: 80px;
    background-color: var(--bs-white);
}

.welcome_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 21px;
    color: var(--bg-theme);
}

.createReqBtn{
    width: 207px;
    height: 54px;
    background-color: var(--bg-theme);
    border-radius: 8px;
    text-align: center;
    outline: 0px;
    border : 1px solid var(--bg-theme);
    padding: 17px 25px;

    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    
    align-items: center;
    text-align: center;
    color: var(--color-2f2);
}

.D_label{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-737);
}

.D_card{
    width: 305px;
    height: 134px;
    background-color: var(--bg-color-cfb);
    border-radius: 24px;
    margin: 12px;
    padding: 15px 25px;
    transition: 0.2s;
    float: left;   
    margin-left: 0px; 
}

.addBuyerPlatforminDashboard {
    width: 48px;
    height: 48px;    
    border: 1px solid var(--bg-theme);
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    margin-left: 12px;
}

.addSign{
    width: 18px;
    height: 21px;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 21px;   
    color: var(--bg-theme);
    margin: 12.5px;
}

.addBuyerPlatforminDashboard_text{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: var(--bg-theme);
    margin-top: 6px;
}

.logoContainer{
    text-align: center;
}

.D_buyerPlatform_Icon_container{
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 8px;
}

.D_buyerPlatform_icon_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: center;   
}

.donetGraphSection{
    background-color: var(--bg-color-cfb);
    border-radius: 24px;
    max-height: 230px;   
}

.subSection{
    height: 103px;
    background: var(--bg-color-cfb);
    border-radius: 24px;
}

.A_subSection{
    height: 102px;
    background-color: var(--bg-color-cfb);
    box-sizing: border-box;
    border-radius: 24px;
}

.column_graph_section{
    height: 359px;
    background-color: var(--bg-color-cfb);
    border-radius: 24px;
    padding-top: 16px;
}

.donutChart{ 
    padding: 18px 20px;  
}

.subSecTitle{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    color: var(--color-c6c);    
}

.totalAmount {
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: var(--bg-theme);
    margin-left: 20px;
}

.totalAmount span {
    display: inline-block;
    vertical-align: middle;
}

.totalAmount p {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 10px;
    font-size: 12px;
}

.subSection-two {
    cursor: pointer;
}

.CR_card_text{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-737);
}

.CR_card_status{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: var(--red);
    margin-bottom: 15px;
}

#Dashboardwrapper {
    height: 180px;
    width: 1250px;
    overflow-x: auto;
    overflow-y: hidden;
    display: inline-flex;
}

#nowrapper{   
    width: 1250px;
    display: flex;
}
 
#Dashboardwrapper::-webkit-scrollbar {
    height: 10px;    
    background-color: var(--color-f5f);
}

#Dashboardwrapper::-webkit-scrollbar-thumb:horizontal{
    background-color: var(--lightgray);
    border-radius: 10px;  
}

#colorCodeforDonutGraph{
    height: 5px;
    width: 8px;
    border-radius: 5px;
}

.CR_buyerPlatform_Icon_container{
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 8px;   
    margin: 5px auto 0;
    text-align: center;    
}

.CR_buyerPlatform_icon_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    margin-top: 6px;
}

._SelectBuyerPlatform_SidePanel {
    height: 95%;
    width: 0px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: var(--bs-white);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    margin-top: 80px;   
    
    box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 0px 24px 0px 0px;
}
  
._SelectBuyerPlatform_SidePanel .closebtn {
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: var(--bg-black);
    cursor: pointer;
}
  
  
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    ._SelectBuyerPlatform_SidePanel {padding-top: 15px;}
}

.SBP_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 21px;
    color: var(--bg-theme);
}

.font{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size:14px;
    color:var(--color-c6c);
}

.imgButton {
    width: 120px;
    height: 120px;
    border: 1px solid var(--border-9d8);
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    padding-top: 20px;
}

.imgButtonActive {
    width: 120px;
    height: 120px;
    border: 3px solid var(--bg-theme);
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    padding-top: 20px;
  }

  
.buttonLabel {
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: var(--color-737);
}
  
.buttonLabelActive {
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: var(--color-737);
}

.Thescroll {
    margin: 4px, 4px;
    padding: 4px;
    background-color: var(--bs-white);  
    height: 460px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
}

.CreateAccformSelectBP{
    width: 238px;
    height: 54px;
    background-color: var(--bg-theme);
    border-radius: 8px;
    border: transparent;
    outline: none;

    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;

    align-items: center;
    text-align: center;

    color: var(--color-2f2);
}

.smWdt {
    -ms-flex: 0 0 93.88887%;
    flex: 0 0 93.88887%;
    max-width: 93.88887%;
}

.remittances {
    position: absolute;
    top: 35px;
    right: 30px;
    z-index: 1;
}

.remittances a {
    display: inline-block;
    color: var(--bg-theme);
    font-weight: bold;
    text-decoration: none;
}

.chart-wrap {
    margin-top: -40px;
}

@media screen and (max-width:1699px) {
    #BuyerPlatformGraphDatainDashboard .ml-4 {
        margin-left: 0 !important;
    }
    .totalAmount {
        font-size: 18px;
    }
}

@media screen and (max-width:1569px) {
    .dashboard-right .col-sm-3 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .donutChart > div {
        height: 240px !important;
    }
    .donetGraphSection .col-sm-5.donutChart {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .donetGraphSection .col-sm-7 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .donetGraphSection .pl-5 {
        padding-left: 15px;
    }
    .donetGraphSection {
        max-height: 500px;
    }
}

@media screen and (max-width:1499px) {
    .createReqBtn  {
        margin-left: 0 !important;
    }
}

@media screen and (max-width:1399px) {
    .D_label {
        font-size: 20px !important;
    }
    .totalAmount {
        font-size: 15px;
    }
    .subSecTitle {
        padding-left: 13px !important;
        padding-right: 13px !important;
    }
}


@media screen and (max-width:1199px) {
    #nowrapper {
        width: auto;
    }
    #container {
        padding: 30px 10px !important;
        margin-left: 0 !important;
    }
}

@media screen and (max-width:991px) {
    #nowrapper {
        display: block;
    }
    .dashboard-right .col-sm-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .welcome_title {
        font-size: 32px;
        line-height: normal;
    }
}

@media screen and (max-width:767px) {
    #container {
        padding: 20px 10px 30px 0 !important;
    }
    .welcome_title {
        padding-bottom: 10px;
        font-size: 28px;
    }
    #BuyerPlatformGraphDatainDashboard .ml-4 {
        margin-left: -15px !important;
    }
    .donetGraphSection {
        margin: 0 15px !important;
    }
}

@media screen and (max-width:575px) {
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
}

@media screen and (max-width:479px) {
    .dashboard-right .col-sm-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}