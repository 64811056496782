#container{    
    border-radius: 40px 40px 0px 0px;
    margin-left: 235px;
    margin-top: 80px;
    background-color: var(--bs-white);
}

.request_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    color: var(--color-737);
    padding-top: 5px;
}

.cursor {
    cursor: pointer;
}

.text-through {
    text-decoration: line-through;
}

.tab{
    width: 142px;
    height: 50px;
    padding: 10px;
    border: 0px;
    background-color: transparent;
    font-family: var(--font);
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    z-index: 1;
}

#tbody{
    border: 1px solid var(--border-9d8);
    box-sizing: border-box;
    border-radius: 8px;
}

#tbody tr{
    margin: 3px;
    padding-top: 8px;
}

.infoPanel {
    height: 95%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color:var(--bg-color-cfb);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 40px;
    margin-top: 80px;   
    
    box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 0px 24px 0px 0px;
  }
    
  .infoPanel .closebtn {
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: var(--bg-black);
    cursor: pointer;
  }
    
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .infoPanel {padding-top: 15px;}
}

.R_panelLabel{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-c6c);
 }

  td{
    border-bottom: 1px solid var(--border-9d8);
  }

  .R_panelText{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 21px;
    color: var(--bg-black);
  }

  .links{      
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color:var(--bg-theme);
}
  

.createReqBtn{
    width: 207px;
    height: 54px;
    background-color: var(--bg-theme);
    border-radius: 8px;
    text-align: center;
    outline: 0px;
    border : 1px solid var(--bg-theme);
    padding: 17px 25px;

    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;    
   
    text-align: center;
    color: var(--color-2f2);
}

button:focus{
    outline: 0px;
}

.Thetext{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--bg-black);   
}

td{
  padding-top: 22px;
}

.label{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-c6c);
}

.R_ID_text{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    color: var(--color-c6c);
}

tr:hover{
  background-color: var(--bg-color-cfb); 
  cursor: pointer;
}

.R_logoContainer{
    width: 89px;
    height: 89px;
    background-color: var(--bs-white);
    border-radius: 16px;
    text-align: center;
    padding-top: 12px;
}

#upperSect{
    height: 65%;
    background-color:var(--bg-color-cfb);
}

#bottomSec{
    height: 35%;
    background-color: var(--bs-white);
    padding: 20px 20px 20px 40px;
}

.R_timerCheckbox{
    width: 24px;
    height: 24px;
    border: 1px solid var(--bg-theme);
    box-sizing: border-box;
    border-radius: 4px;
}

.statusCheck{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-737);
}

input[type=checkbox][disabled] + p{
    color: var(--color-ccc);
}

.dis{
    color: var(--color-ccc);
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
}

.round {
   position: relative;
}
  
.round label {
    background-color: var(--bs-white);
    border: 1px solid var(--color-ccc);
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
}
  
.round label:after {
    border: 2px solid var(--bs-white);
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 4.4px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-45deg);
    width: 8px;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color:  var(--bg-theme);
    border-color:  var(--bg-theme);
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.statusCheckText{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-737);
}

input[type="radio"]:checked {
    background-color: var(--color-fbb);
    border-color: var(--color-c44);    
}

.radio{
    background-color: var(--bs-white);
    border:1px solid var(--bg-theme);
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
    text-align: center;   
}

.regular-checkbox {
	-webkit-appearance: none;
	background-color: var(--bs-white);
	border: 1px solid var(--color-ece);
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
  position: relative;
  
  width: 24px;
  height: 24px;
  border: 1px solid var(--bg-theme);
  border-radius: 4px;
  outline: none;
}

.regular-checkbox:checked {
	background-color: var(--bs-white);
	border: 1px solid var(--bg-theme);
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
  color: var(--yellow);
  outline: none;
}

.regular-checkbox:checked:after {
	content: '\1F5F8';
  font-size: 16px;
  font-weight: bold;
	position: absolute;
	top: 0px;
	left: 3px;
	color: var(--bg-theme);
}

.ReqAcceptBtn{
  width: 207px;
  height: 54px;
  background-color: var(--bg-theme);
  border-radius: 8px;
  border: transparent;
  font-family: var(--font);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  color: var(--color-2f2);
}

.ReqRejectBtn{
  height: 54px;
  background-color: transparent;
  border: transparent;
  font-family: var(--font);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: var(--bg-theme);
}
 

.bottom_side_text{
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-c6c);
}
  
.icnSize {
  width: 24px !important;
  height: 24px;
  color: var(--yellow);
  vertical-align: middle;
}

.coName svg {
  margin-right: 3px;
}  
.coName b {
  color: var(--bg-black);
  padding-right: 5px;
}
.coName small {
  font-family: var(--font);
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  color: var(--color-c6c);
}

.reqNW .font-weight-bold {
  font-weight: bold;
  font-size: 18px;  
  color: var(--bg-black);
}
  
.popUP {
  cursor: pointer;
}  

.modalRight {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  height: 100%;
  left: 100% !important;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out !important;
  background: var(--bg-color-cfb);
  box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0px 24px 0px 0px;
  max-width: 500px;  
}
.modalRight .table tr, .modalRight .table th, .modalRight .table td {
  border: none !important;
}
.modalRight .table p {
  margin-bottom: 8px;
}
.modalRight .table h3 {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 400;
}
.modalRight .table a {
  display: inline-block;
  color: var(--bg-theme);
  text-decoration: underline;
}
h3.color-text {
  color: var(--bg-theme);
  font-weight: 700 !important;
}

.modalRight .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
}
.modal-open .modal {
  margin-right: 0;
}
.modal-open .modalRight {
  left: 0 !important;
}
.modalRight .modal-body {
  padding: 25px 25px 0px 25px;
  overflow-x: hidden;
  overflow-y: auto;
}
.modalRight .modal-header {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.proName h2 {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  margin: 0 0 10px 0;
  color: var(--bg-black);
}
.proName small {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-c6c);
  display: block;
}

.dataSec small {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-c6c);
}
.dataSec strong {
  font-family: var(--font);
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  color: var(--bg-black);
}

.smallSZ {
  width: 10px;
  color: var(--bg-theme);
}

.vwInv {
  font-family: var(--font);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: var(--bg-theme);
}

.tcBox {
  background: var(--bs-white);
  border-top: 1px solid var(--border-9d8);
  min-height: 150px;
  position: static;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 365px;
  margin: 25px 0px 0 -25px;
  padding: 25px;
}

.vldSec {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-737);
}
.vldSec b {
  color: var(--bg-theme); 
}

.txtN.checkTxt {
  float: left;
  width: 90%;
  padding-left: 10px;
  line-height: 1.1;
  margin-top: -4px;
}

.lineHt {
  line-height: 1;
}

.statusData {
  list-style: none;
  margin: 0;
  padding: 0 0 0 90px;
}

small.smallTx {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(--bg-black);
}

.paidBtn,.dueBtn {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  color: var(--bs-white);
  text-transform: uppercase;
  padding: 2px 8px;
  background: var(--bg-theme);
  border-radius: 8px;
  margin-right: 5px;
}

.dueBtn {
  background: var(--red);
}

.statusData li {
  font-size: 14px;
  line-height: 1;
  padding-bottom: 20px;
  padding-left: 30px;
  position: relative;
}

.statusData li.done {
  background:var(--bg-color-cfb) url(img_check.png) no-repeat 0 0 / 16px;
}

.statusData li.process {
  background:var(--bg-color-cfb) url(process.png) no-repeat 0 0 / 16px;
}

.statusData li.disable {
  background:var(--bg-color-cfb) url(disable.png) no-repeat 0 0 / 16px;
}

.statusData li.done:before, 
.statusData li.disable:before {
  width: 1px;
  height: 100%;
  border-left: dashed 1px var(--bg-theme);
  content: '';
  position: absolute;
  left: 7px;
  top: 15px;
}

.statusData li.disable:before {
  bottom: 31px;
  top: auto;
  border-color: var(--color-2f2);
}

@media only screen and (max-width: 768px) {
  #main,.smWdt {
    padding-left: 15px !important; 
    max-width: 100% !important;
  }
  .tableresponsive {
    display: block; 
    width: 100%; 
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
    border: none !important; 
    padding: 0 !important; 
    margin-top: 0 !important;
  }
  .tableHead {
    margin-top: 0 !important;
  }
  .table thead th {
    white-space: nowrap;
  }
  .createReqBtn {
    width: auto !important; 
    padding: 10px 12px !important; 
    font-size: 14px !important; 
    height: auto !important;
  }
  .modalRight {
    border-radius: 0!important;
  }
}

















