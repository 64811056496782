#main_Header{
   position: fixed; 
    height: 72px;
    top:0;
    left:0;
    margin-top: 0px;
    background-color:var(--bg-theme);
    border-radius: 0px 0px 40px 40px;
    z-index: 5;
    display: block;
}

#main_Header .right-content{
    float: right;
    height: inherit;
    text-align: right;
}

.top_left_title {
    position: absolute;
    left: 1.54%;    
    margin-top: 15px;      
    color: var(--color-2f2);
    font-weight: normal;    
}

.bell{
    position: relative;    
    color: var(--color-2f2);   
    margin-top: 21px;
}

._bell{
    transform: scale(2,2);
    position: absolute;
    cursor: pointer;  
    margin-top: 28px;
}

.circle{
    position: absolute;    
    color: var(--color-2f2);
    transform: scale(2,2);
    margin-top: 20px;
}

._circle{
    position: absolute;
    transform: scale(2,2);
    cursor: pointer;
    margin-left: 54px;
    margin-top: 27px;
}

._name{
    padding: 26px 0;
    display: inline-block;
}

._headertext{    
    width: auto;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: var(--bs-white);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 110px;
    cursor: pointer;
}

span text{
    display: block;
    margin-left: 0;
}

.headertext{    
    width: 120px;
    height: 12px;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;   
    color: var(--bs-white);
    text-align: left;
}

.name{
    position: absolute; 
    cursor: pointer;
}

._logout{   
    cursor: pointer;
    margin-left: 164px;
    margin-top: 0;
    margin-right: 50px;    
}

#toggle {
    display: none;
    vertical-align: middle;
    margin-right: 10px;
    width: 25px;
    cursor: pointer;
}
  
#toggle div {
    width: 100%;
    height: 3px;
    background: var(--bs-white);
    margin: 4px auto;
}

@media screen and (max-width:1199px) {
    #toggle {
        display: inline-block;
    }
}

@media screen and (max-width:767px) {
    ._logout {
        margin-right: 10px;
    }   
}