#container{
    border: 1px solid var(--bs-white);
    border-radius: 40px 40px 0px 0px;
    margin-left: 235px;
    margin-top: 80px;
    background-color: var(--bs-white);
    
}

.HomeConatiner{    
    padding-left: 48px;
    padding-top: 30px;
}

.body-title{
    margin-top: 25px;
    margin-left: 42px;   
    padding: 1px;
    width: 638px;
    background: var(--bg-theme);
    border-radius: 24px;
}

.box{
    position: absolute;
    width: 97px;
    height: 73px;
    margin-left: 28px;
    margin-top: 26px;
    text-align: center;
    padding-top: 10px;    
}

.body-title-text{
    margin-left: 150px;
    margin-top: 31px;
    padding: 0px;

    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 21px;
    color: var(--bs-white);
}

.title-text{  
    width: 437px;
    height: 36px;
    margin-left: 151px;
    margin-top: 5px;    
    font-family: var(--bs-white);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;   
    color: var(--color-2f2);    
}

.tell-us-about-your-company{
    margin-left: 62px;    
    margin-top: 190px;
    bottom: 66.56%;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-737);
}

#card{
    width: 305px;
    height: 155px;
    margin-bottom: 30px;
    background-color: var(--bg-color-cfb);
    border-radius: 24px;   
    padding: 10px;
    padding-top: 18px;   
}

.thecard{
    width: 305px;
    height: 134px;
    background-color: var(--bg-color-cfb);
    border-radius: 24px;
    margin: 12px;
    padding: 15px 25px;
    transition: 0.2s;
    float: left;
    margin-left: 0px;
}

.Card_label{
    margin-left: 24px;    
    margin-top: 15px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 0px;
    color: var(--color-737);
}

.card_Text{
    margin-left: 40px;
    margin-top: 10px;
    width: 125px;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;   
    color: var(--color-737);
}


#buyercard_Text{
    margin-left: 25px;
    margin-top: 10px;
    
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;   
    color: var(--color-737);
}

.fillNow {
    position: absolute;
    height: 21px;
    margin-left: 195px;
    margin-top: 55px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: var(--bg-theme);;
}

.cardfillNow {
    height: 21px;   
    margin-top: 10px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: var(--bg-theme);;
}

.sub_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-737);
}

.add-Card{
    position: absolute;
    width: 64px;
    height: 64px;
    left: 1048px;
    top: 450px;
    border: 1px solid var(--color-08a);
    box-sizing: border-box;
    border-radius: 8px;
}

.plus-icon{
    position: absolute;
    width: 21px;
    height: 21px;
    left: 1068px;
    top: 470px;

    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 0px;
    text-align: center;
    color: var(--color-08a);
}

.buyer-plateform-text{
    position: absolute;
    width: 93px;
    height: 36px;
    left: 1033px;
    top: 512px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--color-08a);
}

.verify-your-bank-acc{
    position: absolute;
    left: 46px;    
    top: 580px;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-dbd);
}

.Bank-Acc-card{
    position: absolute;
    width: 366px;
    height: 134px;
    left: 46px;
    top: 640px;
    background: var(--color-8f4);
    border-radius: 24px;
}

.verifyNow{
    position: absolute;
    width: 98px;
    height: 21px;
    left: 235px;
    top: 89px;

    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-0b3);
}

.H_scroll{
    width:900px;
    height:160px;
    white-space: nowrap;
    position: relative;
    overflow-x:scroll;
    overflow-y:hidden;    
}

.H_addBuyerPlatforminHome {
    width: 64px;
    height: 64px;    
    border: 1px solid var(--bg-theme);
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    margin-left: 10px; 
    margin-top: 40px;
    padding : 10px;
}

.addBuyerPlatforminHome {
    width: 64px;
    height: 64px;
    
    border: 1px solid var(--bg-theme);
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    margin-left: 10px; 
    margin-top: 40px;
}

.addSign{
    width: 21px;
    height: 21px;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 21px;   
    color: var(--bg-theme);;
    text-align: center;
}

.H_addSign{
    width: 21px;
    height: 21px;
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 21px;   
    color: var(--bg-theme);
    margin: 20px;
}

.addBuyerPlatforminHome_text{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--bg-theme);
    margin-top: 8px;
    width: 90px;
    text-align: center;
    margin-left: 0px;
}

#wrapper {
    height: 180px;
    width: 1050px;
    overflow-x: scroll;
    overflow-y: hidden;
}

#wrapper::-webkit-scrollbar {
    height: 10px;    
    background-color: var(--color-f5f);
}

#wrapper::-webkit-scrollbar-thumb:horizontal{
    background-color: var(--color-080);
    border-radius: 10px;  
}

.item{
    width: 60px;
    padding: 10px;
    float: left;
}

.H_AED_imp{
    width: 147px;
    height: 54px;
    background-color: var(--bs-white);
    border: 1px solid var(--color-c6c);
    box-sizing: border-box;
    border-radius: 8px;
}

.H_AED_imp::placeholder{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: var(--color-2f2);
}

.bankVerify_H {
    width: 207px;
    height: 54px;
    background-color: var(--bg-theme);
    border-radius: 8px;
    bottom: 10px;

    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    align-items: center;
    text-align: center;

    color: var(--color-2f2);
    margin-top: 460px;
    outline: none;
    border: transparent;
}

.Active_VerifyNumStatus{
    color: var(--darkgreen);
    font-weight: bold;
    margin-top: 10px;
    margin-left: 5px;
}

.VerifyNumStatus{
    color: var(--red);
    font-weight: bold;   
}

.SelectBuyerPlatform_SidePanel {
    height: 95%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color:var(--bs-white);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    margin-top: 80px;   
    
    box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 0px 24px 0px 0px;
}
  
  
.SelectBuyerPlatform_SidePanel .closebtn {
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: var(--bg-black);
    cursor: pointer;
}
  
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .SelectBuyerPlatform_SidePanel {
    padding-top: 15px;
  }
}

.SBP_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 21px;
    color: var(--bg-theme);
}

.font{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size:14px;
    color:var(--color-c6c);
}

.imgButton {
    width: 120px;
    height: 120px;
    border: 1px solid var(--border-9d8);
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    padding-top: 20px;
}

.imgButtonActive {
    width: 120px;
    height: 120px;
    border: 3px solid var(--bg-theme);
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    padding-top: 20px;
}

.buttonLabel {
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: var(--color-737);
}
  
.buttonLabelActive {
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: var(--color-737);
}

.Thescroll {
    margin: 4px, 4px;
    padding: 4px;
    background-color: var(--bs-white);  
    height: 460px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
}

.CreateAccformSelectBP{
    width: 238px;
    height: 54px;
    background-color: var(--bg-theme);
    border-radius: 8px;
    border: transparent;
    outline: none;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    color: var(--color-2f2);
}


@media screen and (max-width:1199px) {
  .HomeConatiner {
    padding: 0;
  }
}

@media screen and (max-width:991px) {
  .body-title {
    width: auto;
    height: auto;
    padding: 5px;
    position: static;
    margin-right: 15px;
  }
  .body-title-text {
    width: auto;
    height: auto;
    line-height: 36px;
  }
  .title-text {
    width: auto;
    height: auto;
  }
  .sub_title {
    font-size: 20px !important;
  }
}

@media screen and (max-width:767px) {
  .sidebar {
    z-index: 1;
  }
  #container {
    margin-left: 0 !important;
  }
  .body-title {
    margin-left: 15px;
  }
  .body-title-text {
    font-size: 28px;
  }
  .title-text {
    padding-right: 10px;
  }
  .sub_title {
    margin-top: 20px !important;
  }
}

@media screen and (max-width:575px) {
  .H_addBuyerPlatforminHome {
    margin-top: 25px;
  }
}

@media screen and (max-width:479px) {
  .name {
    display: none;
  }
  .body-title {
    padding: 15px;
  }
  .box {
    position: static;
    margin: 0;
  }
  .body-title-text {
    margin: 10px 0 0;
  }
  .title-text {
    margin: 0;
  }
  .body-title-text {
    font-size: 24px;
  }
  .sub_title {
    font-size: 16px !important;
  }
  .row.res-fillNow.fillNow {
    margin-left: 180px !important;
  }
}

@media screen and (max-width:359px) {
  .thecard, #card {
    width: 285px !important;
  }
}