#container{
    border: 1px solid var(--bs-white);
    border-radius: 40px 40px 0px 0px;
    margin-left: 235px;
    margin-top: 80px;
    background-color: var(--bs-white);  
}

.viewMerchant_container{
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
}

.label_text{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-737);
}

.text{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 21px;
    color: var(--color-737);
}

.sub_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 21px;
    color: var(--color-737);
    margin-top: 40px;
}

.merchant_name{    
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 21px;
    color: var(--color-737);
    margin-right: 27px;
} 

.reject_merchant{
    background-color: var(--bs-white);
    border: 0;
    outline: 0;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    align-items: left;
    color: var(--bs-danger);
}

.id_date_box{
    height: 103px;
    background: var(--bg-color-8ff);
    border-radius: 8px;
    padding-left: 25px;
    padding-top: 15px;
    margin-top: 30px;
}

.merchant_id_box{
    margin: 10px;
    border-right: 1px solid var(--border-9d8);
    height: 65px;
}

.date_join_box{
    margin: 10px;
    height: 65px;
    width: 120px;
    margin-left: 32px;
}

.account_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 21px;
    color: var(--color-737);   
}

.account_data{
    margin-top: 35px;
}

.reminder{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    color: var(--color-1c7);
    margin-top: 42px;
    margin-left: 18px;
}

.bank_verification{
    width: 256px;
    height: 54px;
    background: var(--bg-theme);
    border-radius: 8px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    color: var(--color-2f2);
}

li {
    list-style: none;
}

.li_data{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: var(--bg-theme);
    margin: 12px;
    margin-left: 0;
}

.active{
    width: 271px;
    height: 32px;
    background: var(--color-2f2);
    border-radius: 8px;
    text-align: center;
    padding-top: 5px;
    margin-left: 0px;
}

.noon_reg_certi{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin: 20px;
    color: var(--bg-theme);
}

.btn{
    height: 54px;
    background-color: var(--bg-theme);
    border-radius: 8px;
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;

    display: flex;
    align-items: center;
    text-align: center;

    color: var(--color-2f2);
    margin: 15px;
    padding-left: 18px;
    margin-bottom: 35px;
}

.edit_button{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    color: var(--bg-theme);
    background-color: var(--bs-white);
    border: 0px;
    outline: 0px;
}

.buyerPlatform_Icon_container{
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 15px auto 0;
    margin-bottom: 8px;
    text-align: center;
    padding-top: 0px;
}

.buyerPlatform_icon_title{
    font-family: var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: center;   
}

.doc_text{
    font-family: var(--font);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: var(--bg-theme);
}

label {
    font-weight: 500;
}