:root{
  --bg-theme: #00c9bf;
  --bg-color-cef: #e9ecef;
  --bg-color-cfb: #effcfb;
  --bg-color-8ff: #f3f8ff;
  --border-9e7: #b5e9e7;
  --border-4da: #ced4da;
  --border-eee: #eeeeee; 
  --border-9d8: #d7d9d8;  
  --color-737: #373737;  
  --color-1c7: #7291c7;
  --color-057: #495057;
  --color-2f2: #f2f2f2;
  --color-7ed: #e3e7ed;
  --color-afa: #fafafa;
  --color-c6c: #6b6c6c;
  --color-f5f: #f5f5f5;
  --color-fe0: #f2dfe0;
  --color-988: #bb8988;
  --color-ddd: #dddddd;
  --color-080: #808080;
  --color-ccc: #cccccc;
  --color-ece: #cacece;
  --color-fbb: #bbffbb;
  --color-c44: #44cc44;  
  --color-171: #3e5171;
  --color-08a: #51b08a;
  --color-dbd: #c0bdbd;
  --color-8f4: #eef8f4;
  --color-0b3: #a1c0b3;
  --color-e4e: #4e4e4e;
  --color-c7c: #7c7c7c;
  --color-fdf: #dfdfdf;
  --body-color: #00263F;
  
  --font: 'Roboto';
  --yellow: yellow;
  --bg-black: #00263F;
  --lightgray: lightgray;
  --darkgreen: darkgreen;
  --red: #D34C5C;
}

body {
  font-family: var(--font);
  color: var(--body-color);
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1, p {
  font-family: var(--font) !important;
}

h3, .h3, .listhead{
  font-size: 1.75rem;
  font-weight: 500;
}

@media print {
  body{
    visibility: hidden;    
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  #section-to-print {
    display: block !important;
  }
}

.clickevent {
  font-size: 14px;
  font-weight: 500;
  color: var(--bg-theme);
  text-decoration: underline;
}

.App {
  background-color: var(--color-2f2);
  margin-top: 0px;
  overflow-x: hidden !important;
}

.modal-header .sr-only {
  display: none;
}

.body-container {
  border: 1px solid var(--bs-white);
  border-radius: 40px 40px 0px 0px;
  margin-left: 235px;
  margin-top: 0px;
  background-color: var(--bs-white);
}

.mainWrap {
  padding: 40px 50px 65px 50px;
}

.link_color {
  color: var(--bg-theme);
  font-size: 14px;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.themeBG {
  background: var(--bg-theme) !important;
}

.themeBG label,
.themeBG p {color: var(--bs-white) !important;}

.teal {
  color: var(--bg-theme);
}

#container {
  border-radius: 40px 40px 0px 0px;
  margin-left: 235px;
  margin-top: 80px;
  background-color: var(--bs-white);
}

.efunder-btn {
  background-color: var(--bg-theme);
  color: var(--bs-white);
  text-align: center;
  display: inline-block;
  padding: 13px 15px;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 18px;
  min-width: 180px;
  font-weight: 700;
}

.efunder-btn:hover, .efunder-btn:focus {
  background-color: var(--bg-theme); 
  color: var(--bs-white);
}
.efunder-btn:hover {
  text-decoration: none;
}
.details-item {
  padding-bottom: 20px;
}
.details-item p {
  margin-bottom: 8px;
}
.details-item h3 {
  font-size: 22px;
}
.inner-table .table th, 
.inner-table .table td {
  padding: 0;
}
.inner-table h3 {
  font-size: 22px;
}
.inner-table .color-text {
  font-weight: bold;
  color: var(--bg-theme);
}
.invoice-tab {
  padding-top: 15px;
  position: relative;
}

.invoice-tab:before {
  position: absolute;
  top: 0;
  left: -30px;
  width: 150%;
  height: 1px;
  border-top: 1px solid var(--color-2f2);
  content: "";
  z-index: 1;
}

.invoice-tab .nav-tabs .active, .tab-content .active {
  width: auto;
  height: auto;
  background: transparent;
  border-radius: 0;
  text-align: center;
  padding-top: 0;
  margin-left: 0;
}

.invoice-tab .nav-tabs {
  border-bottom: none;
}

.invoice-tab .nav {
  display: block;
  padding-bottom: 30px;
  text-align: center;
}

.invoice-tab .nav-tabs .nav-link.active, 
.invoice-tab .nav-tabs .nav-item.show .nav-link {
  font-weight: bold;
  border: none;
  background-color: transparent;
  color: var(--bg-black);
}

.invoice-tab .nav-tabs .nav-item {
  padding: 0;
}

.invoice-tab .nav-tabs .nav-link {
  display: inline-block;
  color: var(--bg-black);
  margin: 0 28px 0 13px;
  border: none;
  font-size: 20px;
  position: relative;
}

.invoice-tab .nav-tabs .nav-link:after {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  opacity: 0;
  background-color: var(--bg-black);
  content: "";
  z-index: 1;
}

.invoice-tab .nav-tabs .nav-link.active:after, 
.invoice-tab .nav-tabs .nav-item.show .nav-link:after {
  opacity: 1;
}

.apexcharts-active {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.bdrDsn {
  max-height: 420px !important;
}

/* Notification CSS */
.notification-wrap .card {
  margin-bottom: 25px;
  border: none;
  background-color: transparent;
}

.notification-wrap .card-header {
  padding: 0;
  cursor: pointer;
  border-bottom: none;
  background-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notification-wrap .card-body {
  padding: 0;
}

.notification-wrap .card-body p {
  margin-bottom: 0;
}

.notification-item h5 {
  color: var(--bg-theme);
  font-weight: 500;
  font-size: 16px;
}

.arrow-down {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  border-style: solid;
  border-color: var(--bg-theme);
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

.notification-item h6 {
  font-weight: 500;
  color: var(--bg-black);
}

.notification-info {
  display: none;
}

.notification-title {
  color: var(--bg-theme);
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.loader .spinner-border {
  width: 70px;
  height: 70px;
  border-right-color: var(--bg-theme);
}

.loader-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.loader-absolute .spinner-border {
  width: 70px;
  height: 70px;
  border-right-color: var(--bg-theme);
}

.loadersheet {
  position: fixed;
  top: 50%;
  left: 50%;
  right: 15%;
  left: 85%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.loadersheet .spinner-border {
  width: 40px;
  height: 40px;
  border-right-color: var(--bg-theme);
}

.loaderlogin {
  position: fixed;
  top: 60%;
  bottom: 40%;
  right: 25%;
  left: 75%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.loaderlogin .spinner-border {
  width: 40px;
  height: 40px;
  border-right-color: var(--bg-theme);
}

.save_n_exit.efunder-btn {
  color: var(--bs-white);
}

button {
  border: none;
  outline: none !important;
  background: none;
}

button:hover, 
button:focus {
  border: none;
  outline: none !important;
}

.selectBPContainer button, 
.selectBPContainer button:hover {
  border: 2px solid var(--border-eee);
}

.selectBPContainer .imgButtonActive, 
.selectBPContainer .imgButtonActive:hover {
  border-color: var(--bg-theme);
}

.regular-checkbox-three {
  display: inline-block;
  vertical-align: middle;
  margin: 0 !important;
  -webkit-appearance: none;
  background-color: var(--bs-white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative !important;
  width: 24px;
  height: 24px;
  border: 1px solid var(--bs-gray-400);
  border-radius: 4px;
  outline: none;
}

.regular-checkbox-three:active {
  border: 1px solid var(--bg-theme);
}

.regular-checkbox-three:checked {
  background-color: var(--bs-white);
  border: 1px solid var(--bg-theme);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: var(--yellow);
  outline: none;
}

.regular-checkbox-three:checked:after {
  content: '\1F5F8';
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 3px;
  color: var(--bg-theme);
}

.form-check-label {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.cursor {
  cursor: pointer !important;
}

.text-through {
  text-decoration: line-through;
}

tr:hover {
  background-color: var(--bg-color-cfb) !important;
}

.rounded_lg {
  border-radius: 10px;
}

label {
  display: block; 
  margin-bottom: .3rem;
}

.form-control {
  height: 44px;
}

.form-control:focus {
  border-color: var(--border-9e7) !important;
  box-shadow: 0 0 0 0.15rem rgb(22 158 154 / 10%) !important;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 44px;
  margin-bottom: 0;
}

.input-group>.custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 44px;
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 44px;
  padding: .375rem .75rem;
  line-height: 30px;
  color: var(--color-057);
  background-color: var(--bs-white);
  border: 1px solid var(--border-4da);
  border-radius: .25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  padding: .375rem .75rem;
  line-height: 30px;
  color: var(--color-057);
  content: "Browse";
  background-color: var(--bg-color-cef);
  border-left: 1px solid var(--border-4da);
  border-radius: 0 .25rem .25rem 0;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}

button.btn-small {
  min-width: auto;
  padding: 0 15px;
  font-size: 16px;
  line-height: 1;
  height: 44px;
}

.btn:hover {
  color: var(--bs-white) !important;
}

.fl-right {
  float: right;
}

.theme-color {
  color: var(--bg-theme);
}

.head_bg {
  margin-top: -1.5rem;
}

.userProfile label {
  color: var(--bg-black);
  font-weight: 600;
}

.userProfile p.fs-5 {
  font-weight: 600; 
  color: var(--bs-gray-600);
}

.userProfile hr {
  color: var(--color-7ed); 
  opacity: 1; 
  height: 2px !important;
}

.userProfile .id_date_box {
  background: var(--color-afa); 
  border: solid 1px var(--color-7ed);
}

.userProfile h3 {
  color: var(--body-color) !important;
  font-size: 1.75rem;
  font-weight: 500;
}

/*Modal Right*/
/*Modal Right*/
/*Modal Right*/

.fade.modal-backdrop,
.mdlDir .modal-header,
.mdlDir .modal-body,
.mdlDir {
  background: transparent;
}

.mdlDir .modal-header {
  box-shadow: none;
  padding-top: 30px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
}

.pb-100.modal-body {
  padding-top: 0 !important; 
  padding-bottom: 100px !important;
}

.alignClose {
  position: relative;
  padding-top: 40px !important;
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  align-items: baseline !important;
  -webkit-align-items: baseline !important;
}

.alignClose button {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 1;
}

.ModalDemo {
  margin-top: 80px;
}

.modal.ModalDemo .modal-dialog {
  width: 500px;
  height: 100%;
  height: calc(100% - 70px);
  margin-top: 0;
  margin-right: 0;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.ModalDemo .modal-content {
  height: 100%;
  overflow-y: auto;
  background: var(--bg-color-cfb);
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  box-shadow: -4px 0 6px rgb(0 0 0 / 6%);
  border-radius: 0 24px 0 0;
}

.modal.ModalDemo .modal-body {
  padding: 15px 15px 80px;
}

.fwbold {
  font-weight: 500;
}

/*Right*/
.modal.ModalDemo.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.ModalDemo.fade.show .modal-dialog {
  right: 0;
}

/*Modal Right*/
/*Modal Right*/
/*Modal Right*/

@media screen and (max-width:1199px) {
  #container {
    padding: 30px 10px !important;
    margin-left: 0 !important;
  }
  #main {
    padding: 0 !important;
  }
  .userProfile .col-lg-8,
  .userProfile .col-lg-11 {
    width: 100%;
  }
}
@media screen and (max-width:991px) {
  .viewMerchant_container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .userProfile .form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .userProfile .form-group label {
    font-size: 15px !important;
  }
  .userProfile .form-group p {
    line-height: 22px;
    text-align: right;
    font-size: 15px !important;
  }
  .userProfile hr {
    margin-top: 0 !important;
  }
  .userProfile .id_date_box,
  .userProfile .id_date_box .row {
    margin: 0;
    padding-bottom: 0 !important;
  }
  .viewMerchant_container {
    padding-left: 10px !important;
  }
}
@media screen and (max-width:767px) {
  #container {
    padding: 20px 10px 30px 10px !important;
  }
}
@media screen and (max-width:575px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto !important;
    max-width: 100%;
  }
}